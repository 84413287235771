import React, { useState } from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import Select from "react-select";
import GridContainer from "../GridContainer";
import PurchaseCard from "../molecules/PurchaseCard/PurchaseCard";
import PurchaseTitle from "./PurchaseTitle";

import { SelectStyles } from "./Select";
import { PurchaseSection } from "./styles";

import imgBackground from "../../images/purchase-bg-desktop.jpg";
import lightning from "../../images/lightning3.png";

export const fragment = graphql`
  fragment PurchaseLandingFragment on Contentstack_jfg_purchase_page {
    heading
    subheading
    purchaseLinksHeading: purchase_links_heading
    gameEditions: game_editions {
      heading
      shortHeading: short_heading
      features {
        desktopImage: desktop_image {
          url
        }
        mobileImage: mobile_image {
          url
        }
        description
      }
      primaryPurchaseLinks: primary_purchase_links {
        purchaseLink: purchase_link {
          href
          title
        }
        platform {
          platformLogo: platform_logo {
            url
            svg {
              content
            }
          }
        }
      }
      secondaryPurchaseLinks: secondary_purchase_links {
        purchaseLink: purchase_link {
          href
          title
        }
        platform {
          buttonImage: button_image {
            url
          }
        }
      }
    }
    gameLogo: game_logo {
      url
    }
  }
`;

export interface PrimaryPurchaseLink {
  purchaseLink: {
    href: string;
    title: string;
  };
  platform: [
    {
      platformLogo: {
        url: string;
        svg: {
          content: string;
        };
      };
    },
  ];
}

export interface SecondaryPurchaseLink {
  purchaseLink: {
    href: string;
    title: string;
  };
  platform: [
    {
      buttonImage: {
        url: string;
      };
    },
  ];
}

export interface Features {
  desktopImage: {
    url: string;
  };
  mobileImage: {
    url: string;
  };
  description: string;
}

export interface GameEdition {
  heading: string;
  shortHeading: string;
  features: Features;
  primaryPurchaseLinks: PrimaryPurchaseLink[];
  secondaryPurchaseLinks: SecondaryPurchaseLink[];
}
export interface GameLogo {
  url: string;
}

export interface PurchaseData {
  heading: string;
  subheading: string;
  purchaseLinksHeading: string;
  gameEditions: GameEdition[];
  gameLogo: GameLogo;
}

interface Props {
  data: PurchaseData;
  withBackground?: boolean;
  withBgFade?: boolean;
}

export default function Purchase(props: Props) {
  const { heading, subheading, purchaseLinksHeading, gameLogo, gameEditions } = props.data;

  const bgClass = props.withBackground ? "withBackground" : "";
  const bgImage = props.withBackground ? `url("${imgBackground}")` : "";

  const tabWidth = `${(100 / gameEditions.length).toFixed(1)}%`;

  const [activeItem, setActiveItem] = useState(0);

  //set tab names
  const renderTabs = () => {
    return gameEditions.map((tab: { shortHeading: string }, index: number) => {
      const activeClass = index === activeItem ? "active" : "";
      return (
        <div
          className={`tabOuter ${activeClass}`}
          style={{ width: tabWidth }}
          key={`slide-${index}`}
          onClick={() => setActiveItem(index)}
        >
          <div className="tab-text">{tab?.shortHeading}</div>
        </div>
      );
    });
  };

  //render edition info based on active tab
  const renderEdition = () => {
    return gameEditions.map((item: GameEdition, index: number) => {
      return (
        <PurchaseCard
          linksHeading={purchaseLinksHeading}
          activeItem={activeItem}
          cardData={item}
          logo={gameLogo}
          index={index}
          key={index}
        ></PurchaseCard>
      );
    });
  };

  //mobile dropdown
  const options = gameEditions.map((item, index) => ({
    label: item.shortHeading,
    value: index,
    index: index,
  }));

  return (
    <PurchaseSection className={bgClass} style={{ backgroundImage: bgImage }} tabIndex={-1}>
      {props.withBgFade && <div className="bgFade"></div>}
      <GridContainer className="gridOut">
        <PurchaseTitle colorTheme="light" heading={heading} subheading={subheading}>
          <img className="lightning" src={lightning} alt="" />
          <div className="gridIn">
            {/* Mobile Dropdown */}
            <div className="topNavDropdown">
              <Select
              id="long-value-select" instanceId="long-value-select"
                options={options}
                value={options[activeItem]}
                styles={SelectStyles(false)}
                onChange={(e: any) => {
                  setActiveItem(e?.index);
                }}
              />
            </div>
            {/* Desktop Tabs */}
            <div className="topNavTabs">
              {renderTabs()}
              <div
                className="tabHighlight"
                style={{ width: tabWidth, transform: `translateX(${activeItem * 100}%)` }}
              ></div>
            </div>
            <div>{renderEdition()}</div>
          </div>
        </PurchaseTitle>
      </GridContainer>
    </PurchaseSection>
  );
}